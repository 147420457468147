<template>
    <v-app>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col style="margin-top: -100px" cols="12" sm="8" md="8">
                    <v-card class="opa elevation-12">
                        <v-card-title>FAQ</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-list>
                                    <v-list-group v-for="item in items" :key="item.title" v-model="item.active"
                                        :prepend-icon="item.icon" no-action>
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>

                                        <v-list-group v-for="q in item.questions" :key="q.question" no-action sub-group>
                                            <template v-slot:activator>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ q.question }}</v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>{{ q.answer }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list-group>
                                </v-list>
                            </v-container>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";

export default {
    name: "Faq",
    data() {
        return {
            items: [
                {
                    title: 'Groupe 1',
                    active: false,
                    questions: [
                        {
                            question: 'Question 1.1?',
                            answer: 'Réponse à la question 1.1.'
                        },
                        {
                            question: 'Question 1.2?',
                            answer: 'Réponse à la question 1.2.'
                        },
                        {
                            question: 'Question 1.3?',
                            answer: 'Réponse à la question 1.3.'
                        },
                    ]
                },
                {
                    title: 'Groupe 2',
                    active: false,
                    questions: [
                        {
                            question: 'Question 2.1?',
                            answer: 'Réponse à la question 2.1.'
                        },
                        {
                            question: 'Question 2.2?',
                            answer: 'Réponse à la question 2.2.'
                        },
                        {
                            question: 'Question 2.3?',
                            answer: 'Réponse à la question 2.3.'
                        },
                    ]
                },
                // Ajoutez d'autres groupes et questions si nécessaire
            ]
        }
    },
    methods: {

    }
};
</script>