import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticStyle:{"margin-top":"-100px"},attrs:{"cols":"12","sm":"8","md":"8"}},[_c(VCard,{staticClass:"opa elevation-12"},[_c(VCardTitle,[_vm._v("FAQ")]),_c(VCardText,[_c(VContainer,[_c(VList,_vm._l((_vm.items),function(item){return _c(VListGroup,{key:item.title,attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.questions),function(q){return _c(VListGroup,{key:q.question,attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(q.question))])],1)]},proxy:true}],null,true)},[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(_vm._s(q.answer))])],1)],1)],1)}),1)}),1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }